@import "../../node_modules/bootstrap/scss/_functions";
@import "theme/functions";

@import "../../node_modules/bootstrap/scss/mixins";
@import "theme/mixins";

@import "user/user-variables";
@import "theme/variables";
@import "../../node_modules/bootstrap/scss/variables";

// Hamburger
.navbar-toggler-humburger-icon {

  .navbar-toggle-icon {
    .toggle-line, &:after, &:before {
      background-color: rgba($slx-white, .9);
    }
  }
  @include hover-focus {
    .navbar-toggle-icon {
      .toggle-line, &:after, &:before {
        background-color: rgba($slx-dark, .9);
      }
    }
  }
}
// Theme Control Toggle
.theme-control-toggle {
  .theme-control-toggle-label {
    background-color: rgba($slx-white, .1);
    color: rgba($slx-white, .5);
  }
  @include hover-focus {
    .theme-control-toggle-label {
      background-color: rgba($slx-white, .4);
      color: rgba($slx-white, .7);
    }
  }
}

:root {
  --falcon-midnight: #233343;
}






